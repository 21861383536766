<template>
  <div>
    <div v-if="showBanner" class="page-overlay"></div>
    <div v-if="showBanner" class="cookie-banner">
      <p style="color: #003366"><b>Aktueller Status:</b> {{consentMessage}}</p>
      <p>Damit wir Cookies zur Nutzung unseres Webanalyse-Tools <strong> Matomo Analytics </strong>verwenden dürfen, benötigen wir Ihre Einwilligung.
        Es steht Ihnen frei, die Webanalyse abzulehnen oder zu akzeptieren und Ihre diesbezügliche Entscheidung jederzeit zu ändern.
        Wenn Sie die Webanalyse ablehnen, können Sie den Konfliktnavigator dennoch nutzen. Da der Konfliktnavigator Ihnen zweierlei zur Verfügung steht
        (Unterstützung bei der Erstellung einer Vertragsklausel oder bei der Suche nach dem für Sie passenden Streitbeilegungsverfahren),
        erteilen Sie Ihre Einwilligung oder Ablehnung zur Webanalyse einheitlich für beide Nutzungsmöglichkeiten.</p>
      <p>Sofern Sie uns Ihre Einwilligung erteilt haben, verwenden wir Cookies zur Nutzung unseres Webanalyse-Tools <strong>
        Matomo Analytics</strong>. Durch einen Klick auf den Button <strong>„Webanalyse akzeptieren“</strong> erteilen
        Sie uns Ihre Einwilligung dahingehend, dass wir zu Analysezwecken Cookies setzen und die sich ergebenden Daten
        verarbeiten dürfen. Die Rechtsgrundlage ist § 25 Abs. 1 S. 1 TDDDG. Ihr Nutzungsverhalten wird anonymisiert ausgewertet.</p>
      <p>Sie können Ihre Einwilligung zur Webanalyse oder Ablehnung der Webanalyse jederzeit ändern, indem Sie auf der Seite links oben das Schlosssymbol <img
          src="../../public/images/lock-small.svg"/>anklicken und dann <strong>"Webanalyse
        ablehnen"</strong> oder <strong>"Webanalyse akzeptieren"</strong> auswählen.
        Ebenso können Sie bei Klick auf das Schlosssymbol einsehen, ob Sie aktuell die Webanalyse akzeptiert oder abgelehnt haben (<strong>"Aktueller Status"</strong>).

      </p>
      <!-- Expandable section -->
      <div v-if="isTextExpanded">
        <!-- The content to show when expanded -->
        <p ><b>Das Webanalyse-Tool Matomo verwendet folgende Cookies:</b><br></p>
        <ul >
          <li class="cookielist">
            <b>_pk_id</b>
            <br> Typ: Trackingcookie
            <br> Beschreibung: Dieses Cookie wird verwendet, um einige Details über den Websitebesucher zu speichern, wie zum Beispiel die eindeutige Besucher-ID.
            <br> Zweck: Tracking
            <br> Speicherdauer: 12 Monate
          </li>
          <li class="cookielist">
            <b>_pk_ses, _pk_cvar, _pk_hsr</b>
            <br> Typ: Trackingcookie
            <br> Beschreibung: Diese Cookies speichern kurzfristig Daten über den Besuch der Website (z.B. den Browserverlauf).
            <br> Zweck: Tracking
            <br> Speicherdauer: 30 Minuten
          </li>
          <li class="cookielist">
            <b>_pk_ref</b>
            <br> Typ: Trackingcookie
            <br> Beschreibung: Dieses Cookie speichert die Referenz auf die Website, die einen Besucher auf die aktuelle Seite geführt hat.
            <br> Zweck: Tracking
            <br> Speicherdauer: 6 Monate
          </li>
          <li class="cookielist">
            <b>mtm_consent</b>
            <br> Typ: Funktionscookie
            <br> Beschreibung: Dieses Cookie dient der Speicherung der Einwilligung zum Tracking.
            <br> Zweck: Funktional
            <br> Speicherdauer: 12 Monate
          </li>
          <li class="cookielist">
            <b>mtm_consent_removed</b>
            <br> Typ: Funktionscookie
            <br> Beschreibung: Dieses Cookie dient der Speicherung der Ablehnung des Trackings.
            <br> Zweck: Funktional
            <br> Speicherdauer: 12 Monate
          </li>
        </ul>
        <!-- end cookie content -->

      </div>

      <div class="button-container">
        <div>
          <a href="javascript:void(0)" @click="toggleText" class="custom-link">{{
              isTextExpanded ? 'Cookies Informationen ausblenden' : 'Cookies Informationen anzeigen'
            }}</a>
        </div>
        <div><a :href=this.impressumLink target="_blank" class="custom-link"
               >Impressum</a>
        </div>
        <div><a :href=this.datenschutzLink target="_blank" class="custom-link" style=" padding: 0.2em 0;">Datenschutz</a></div>

        <button @click="acceptCookies">Webanalyse akzeptieren</button>
        <button @click="rejectCookies">Webanalyse ablehnen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBanner: false,
      isTextExpanded: false,
      matomoConsent: null,
      datenschutzLink: "https://schiedsgerichtshof.de/wp-content/uploads/2024/11/Datenschutzerklaerung_Website_SGH.pdf",
      impressumLink: "https://schiedsgerichtshof.de/impressum" ,
    };
  },
  computed: {
    // Computed property for conditional consent message
    // eslint-disable-next-line vue/return-in-computed-property
    consentMessage() {

      if (!this.matomoConsent) {
        return 'Noch keine Einwilligung erteilt.';
      } else if (this.matomoConsent === 'accepted') {

        return 'Webanalyse akzeptiert.';
      } else if (this.matomoConsent === 'rejected') {

        return 'Webanalyse abgelehnt.';
      }
    }
  },
  mounted() {
    // Check if the user has already made a choice about cookies
    this.matomoConsent = localStorage.getItem('matomoConsent');
    if (!this.matomoConsent) {
      this.showBanner = true;
      console.debug ("initial consent", this.matomoConsent);
    }
  },

  methods: {
    toggleText() {
      this.isTextExpanded = !this.isTextExpanded;
    },
    acceptCookies() {
      // Set a flag in local storage to indicate matomo tracking is accepted
      localStorage.setItem('matomoConsent', 'accepted');
      this.matomoConsent = 'accepted';
      //change matomo settings
      // eslint-disable-next-line no-undef
      _paq.push(['rememberConsentGiven', 8760]);
      this.showBanner = false;
    },
    rejectCookies() {
      // Set a flag in local storage to indicate matomo tracking is rejected
      localStorage.setItem('matomoConsent', 'rejected');
      //localStorage.removeItem('matomoConsent'); //->only for debugging issues
      this.matomoConsent = 'rejected';

      //change matomo settings
      // eslint-disable-next-line no-undef
      _paq.push(['forgetConsentGiven']);
      this.showBanner = false;
    },
    showCookieBanner() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      console.debug ("consent", this.matomoConsent);
      this.showBanner = true;
    },
  },
};
</script>

<style>
</style>
